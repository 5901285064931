$dot-size: 30px;

.year {
  $line-size: 20px;

  height: $dot-size;
  width: $dot-size;
  border-radius: 50%;
  line-height: $dot-size;
  text-align: center;
  font-size: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  position: relative;

  &:not(.first) {
    margin-top: $line-size * 3;

    &:before {
      content: '';
      height: $line-size;
      position: absolute;
      border-left: 1px dotted var(--color);
      top: -$line-size * 2;
      left: 50%;
    }
  }

  &:not(:last-child) {
    margin-bottom: $line-size * 3;
    &:after {
      content: '';
      height: $line-size;
      position: absolute;
      border-left: 1px dotted var(--color);
      bottom: -$line-size * 2;
      left: 50%;
    }
  }
}

.experience {
  text-align: center;

  .card {
    .title {
      font-size: 1em;

      .duration {
        display: none;
      }

      margin-bottom: 5px;
    }

    .company {
      color: var(--text-color-light);
      margin-bottom: 10px;
    }

    .description {
      text-align: justify;
      padding-top: 5px;
      color: --text-color;
      font-size: 0.9em;
      line-height: 1.2em;
    }

    .tags {
      margin-top: 10px;
      font-size: 0.7em;
      line-height: 2em;
      padding: 0 20%;
      color: var(--text-color-light);
    }
  }
}
