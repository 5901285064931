.definition-list {
  > .group {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .title {
      color: var(--color);
      display: flex;
      margin-bottom: 5px;
      font-size: 1.1em;

      &:after {
        content: '';
        flex: 1;
        margin: 9px 5px auto 5px;
        height: 0;
        border-top: dotted var(--text-color-light) 1px;
      }
    }

    .items {
      font-size: 0.9em;
      line-height: 1.5em;
      padding-left: 15px;

      .item {
        &:before {
          content: '- ';
        }
      }
    }
  }
}
